import { Tab } from "@headlessui/react";
import { useMemo } from "react";
import AOS from "aos";
import "aos/dist/aos.css";
import { TableBuilder } from "../items/TableBuilder";
import { FloatButton } from "../items/FloatButton";
import { exportTable } from "../../utils";

AOS.init();

interface Props {
  data: any;
}

const CampaignTab: React.FC<Props> = ({ data }) => {
  const categories = useMemo(
    () => ({
      "Farmer Table": (
        <TableBuilder
          table="farmer"
          incoming_data={data?.farmer_data}
          headings={[
            "registered_mobile_number",
            "name",
            "brand",
            "zone",
            "alternative_mobile_number",
            "created_at",
            "subscription_fee",
            "subscription_due_date",
            "status",
          ]}
        />
      ),
      Inbox: (
        <TableBuilder
          table="alerts"
          incoming_data={data?.inbox_data}
          headings={[
            "reported_time",
            "from",
            "to",
            "message",
            "reporter_no",
            "report_to_farmer",
          ]}
        />
      ),
    }),
    [data]
  );

  return (
    <div className="w-full">
      <Tab.Group>
        <Tab.List className="flex space-x-1 rounded-xl p-1">
          {Object.keys(categories).map((category) => (
            <Tab
              key={category}
              className={({ selected }) => {
                return `w-full rounded-lg py-2.5 text-sm font-medium leading-5 focus:outline-none transition-colors duration-300 ease-in-out
                  ${
                    selected
                      ? "bg-purple-600 text-white shadow"
                      : " hover:text-purple-600 hover:bg-purple-50"
                  }`;
              }}
            >
              {category}
            </Tab>
          ))}
        </Tab.List>
        <Tab.Panels className="min-h-[calc(100vh-124px)] px-5 overflow-auto bg-gray-100">
          {Object.values(categories).map((category, index) => (
            <Tab.Panel className=" focus:outline-none" key={index}>
              {category}
              <FloatButton
                position="fixed right-5 bottom-[140px]"
                label="Export"
                onClick={() => exportTable(category?.props?.table)}
              />
            </Tab.Panel>
          ))}
        </Tab.Panels>
      </Tab.Group>
    </div>
  );
};
export default CampaignTab;
