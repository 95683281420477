import { useEffect, useRef, useState } from "react";
import StorageLayout from "../components/layout/StorageLayout";
import AOS from "aos";
import "aos/dist/aos.css";
import TableSelector from "../components/sections/TableSelector";
import { FloatButton } from "../components/items/FloatButton";
import { useNavigate } from "react-router-dom";
import { getInbox, getFarmer, uploadFarmerFile } from "../utils";
import LoadingBar from "react-top-loading-bar";
import toast from "react-hot-toast";

AOS.init();

const Storage = () => {
  const [search, setSearch] = useState(""),
    [data, setData] = useState<any>(),
    navigate = useNavigate(),
    ref = useRef<HTMLInputElement>(null),
    progressRef = useRef<any>(null);

  const loadData = async () => {
    const farmer_data = await getFarmer();
    const inbox_data = await getInbox();
    setData({ farmer_data: farmer_data, inbox_data: inbox_data });
  };

  const handleUpload = (e: any) => {
    if (e?.target?.files?.[0])
      toast.promise(uploadFarmerFile(e.target.files[0]), {
        loading: "Uploading...",
        success: () => {
          loadData();
          return "Farmers added sucessfully";
        },
        error: (err) => err.message || "An unexpected error occurred",
      });
  };

  useEffect(() => {
    if (!data) return;
    (async () => {
      const farmer_data = await getFarmer(1, search);
      const inbox_data = await getInbox(1, search);
      setData({ farmer_data: farmer_data, inbox_data: inbox_data });
    })();
    // eslint-disable-next-line
  }, [search]);

  useEffect(() => {
    (async () => {
      progressRef.current.continuousStart();
      await loadData();
      progressRef.current.complete();
    })();
  }, []);

  return (
    <StorageLayout showSearch={true} setSearch={setSearch}>
      <LoadingBar
        color="rgb(147 51 234)"
        height={2}
        ref={progressRef}
        waitingTime={200}
      />
      {data && <TableSelector data={data} />}
      <FloatButton
        position="fixed bottom-[60px] right-5"
        label="Upload"
        onClick={() => ref?.current?.click()}
      />
      <FloatButton
        position="fixed bottom-5 right-5"
        label="Register"
        onClick={() => navigate("/register")}
      />
      <FloatButton
        position="fixed right-5 bottom-[100px]"
        label="Broadcast"
        onClick={() => navigate("/broadcast")}
      />
      <input
        onClick={(event) => ((event.target as HTMLInputElement).value = null)}
        onChange={handleUpload}
        className="hidden"
        ref={ref}
        type="file"
      />
    </StorageLayout>
  );
};

export default Storage;
