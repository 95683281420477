import { useEffect, useState } from "react";

interface Props {
  className?: string;
  label: string;
  color?: string;
  type?: "button" | "submit" | "reset";
  onClick: (arg?: any) => void;
}

export const Button: React.FC<Props> = ({
  className,
  label,
  type,
  color,
  onClick,
}) => {
  const [buttonColor, setButtonColor] = useState("");
  useEffect(() => {
    setButtonColor(`bg-${color ?? "purple"}-600`);
  }, [color]);
  return (
    <button
      type={type ?? "submit"}
      className={
        `${
          className ?? "w-fit"
        } border whitespace-nowrap rounded-lg text-white font-semibold py-2 px-4 ` +
        buttonColor
      }
      onClick={() => onClick()}
    >
      {label}
    </button>
  );
};
