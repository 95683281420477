import axios from "axios";
import dayjs from "dayjs";
import { saveAs } from "file-saver";

export const getClientId = () => {
  return "40427798717-6jsj4oepvjvhbepfe42me9ovslr71lv3.apps.googleusercontent.com";
};

const axiosInstance = axios.create({
  baseURL: "https://cattle-api.me.co.bw/",
});

export const getNextSchedule = (
  scheduled_for: string,
  frequency: string,
  frequency_type: "day" | "month" | "year"
) => {
  while (dayjs().isAfter(dayjs(scheduled_for))) {
    scheduled_for = dayjs(scheduled_for)
      .add(parseInt(frequency), frequency_type)
      .toISOString();
  }
  return dayjs(scheduled_for).format("lll");
};

export const handleLogout = () => {
  google.accounts.id.initialize({
    client_id: getClientId() ?? "",
    auto_select: true,
  });
  localStorage.removeItem("token");
  localStorage.removeItem("userData");
  google.accounts.id.disableAutoSelect();
  window.location.href = "/";
};

export const getFarmer = async (page?: number, query?: string) => {
  try {
    const { data } = await axiosInstance.get(
      `v1/farmer?limit=25${query ? "&query=" + query : ""}&page=${
        page ? page : "1"
      }`,
      { headers: { Authorization: `Bearer ${localStorage.getItem("token")}` } }
    );
    return data?.data ?? {};
  } catch (error) {
    console.error(error);
    if (error?.response.status) window.location.pathname = "/401";
  }
};

export const getInbox = async (page?: number, query?: string) => {
  try {
    const { data } = await axiosInstance.get(
      `v1/alerts?limit=25${query ? "&query=" + query : ""}&page=${
        page ? page : "1"
      }`,
      { headers: { Authorization: `Bearer ${localStorage.getItem("token")}` } }
    );
    return data?.data ?? {};
  } catch (error) {
    if (error?.response.status) window.location.pathname = "/401";
  }
};

export const getBroadcast = async (page?: number, query?: string) => {
  try {
    const { data } = await axiosInstance.get(
      `v1/broadcast?limit=25${query ? "&query=" + query : ""}&page=${
        page ? page : "1"
      }`,
      {
        headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
      }
    );
    return data?.data ?? {};
  } catch (error) {
    console.error(error);
    if (error?.response.status) window.location.pathname = "/401";
  }
};

export const getUsers = async (page?: number, query?: string) => {
  try {
    const { data } = await axiosInstance.get(
      `v1/users?limit=25${query ? "&query=" + query : ""}&page=${
        page ? page : "1"
      }`,
      { headers: { Authorization: `Bearer ${localStorage.getItem("token")}` } }
    );
    return data?.data ?? {};
  } catch (error) {
    console.error(error);
    if (error?.response.status) window.location.pathname = "/401";
  }
};

export const loadMoreData = async (
  table: string,
  page: number,
  query: string
) => {
  switch (table) {
    case "farmer":
      return await getFarmer(page, query);
    case "alerts":
      return await getInbox(page, query);
    case "broadcast":
      return await getBroadcast(page, query);
    case "users":
      return await getBroadcast(page, query);
  }
};

export const updateFarmer = async (formData: {}) => {
  try {
    const { data } = await axiosInstance.post("v1/farmer", formData, {
      headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
    });
    return data;
  } catch (error) {
    console.error(error);
    throw error;
  }
};

export const updatePreRegister = async (formData: {}) => {
  try {
    const { data } = await axiosInstance.post("v1/preregister", formData, {
      headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
    });
    return data;
  } catch (error) {
    console.error(error);
    throw error;
  }
};

export const updateUser = async (userData: {}) => {
  try {
    const { data } = await axiosInstance.post("v1/users", userData, {
      headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
    });
    return data;
  } catch (error) {
    console.error(error);
    throw error;
  }
};

export const getPreRegister = async () => {
  try {
    const { data } = await axiosInstance.get("v1/preregister", {
      headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
    });
    return data?.data ?? {};
  } catch (error) {
    console.error(error);
    if (error?.response.status) window.location.pathname = "/401";
  }
};

export const updateTemplates = async (input: any) => {
  try {
    let formData = [{}];
    Object.keys(input).forEach((template: string, key) => {
      if (template !== "broadcast_templates")
        formData[key] = {
          template_type: template,
          template_message: input[template],
        };
      else
        formData[key] = {
          template_type: template,
          broadcast_message: input[template],
        };
    });
    const { data } = await axiosInstance.post("v1/templates", formData, {
      headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
    });
    return data;
  } catch (error) {
    console.error(error);
    throw error;
  }
};

export const getTemplates = async () => {
  try {
    const { data } = await axiosInstance.get("v1/templates", {
      headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
    });
    let resultData = {
      "farmer-enquiry-error": "",
      "farmer-enquiry-message": "",
      "user-alert-error": "",
      "user-alert-not-found": "",
      "user-farmer-alert-message": "",
      "user-alert-confirmation": "",
      "preregister-error": "",
      "preregister-completion": "",
      "preregister-subscription-alert": "",
      "preregister-confirmation": "",
      broadcast_templates: [],
    };
    data?.data?.forEach((template: any) => {
      if (template.template_type !== "broadcast_templates")
        resultData[template.template_type] = template.template_message;
      else resultData[template.template_type] = template.broadcast_message;
    });
    return resultData;
  } catch (error) {
    console.error(error);
  }
};

export const getZones = async () => {
  try {
    const { data } = await axiosInstance.get("v1/zones", {
      headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
    });
    return data;
  } catch (error) {
    console.error(error);
  }
};
export const deleteTemplate = async (id: number) => {
  try {
    await axiosInstance.delete(`v1/${id}/templates`, {
      headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
    });
  } catch (error) {
    console.error(error);
    throw error;
  }
};

export const uploadFarmerFile = async (file: File) => {
  try {
    let formData = new FormData();
    formData.append("file", file);
    const { data } = await axiosInstance.post("v1/file/farmer", formData, {
      headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
    });
    return data?.data ?? {};
  } catch (error) {
    console.error(error);
    throw error;
  }
};

export const addBroadcast = async (formData: {}) => {
  try {
    const { data } = await axiosInstance.post("v1/broadcast", formData, {
      headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
    });
    if (data?.data)
      setTimeout(function () {
        window.location.href = "/broadcast";
      }, 500);
  } catch (error) {
    console.error(error);
    throw error;
  }
};

export const deleteBrand = async (id: number, brand: string) => {
  try {
    const { data } = await axiosInstance.delete(
      `v1/farmer/${id}/brand/${brand}`,
      {
        headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
      }
    );
    return data;
  } catch (error) {
    console.error(error);
    throw error;
  }
};
export const deleteZone = async (id: number, zone: string) => {
  try {
    const { data } = await axiosInstance.delete(
      `v1/farmer/${id}/zone/${zone}`,
      {
        headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
      }
    );
    return data;
  } catch (error) {
    console.error(error);
    throw error;
  }
};

export const exportTable = async (table: string) => {
  try {
    const token = localStorage.getItem("token");

    const xhr = new XMLHttpRequest();
    xhr.open(
      "GET",
      `https://cattle-api.me.co.bw/v1/${
        table === "alert" ? "alerts" : table
      }/export`
    );
    xhr.setRequestHeader("Authorization", `Bearer ${token}`);
    xhr.responseType = "blob";

    xhr.onload = function () {
      if (xhr.status === 200) {
        const blob = xhr.response;
        saveAs(blob);
      }
    };

    xhr.send();
  } catch (error) {
    console.error(error);
  }
};
